<template>
<div class="card card-custom gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Αγορασμένες υποθέσεις
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          του συνδεδεμένου χρήστη
        </span>
      </h3>
      <div class="card-toolbar">
        <a href="#" class="btn btn-success font-weight-bolder font-size-sm">
          Export
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table
          class="table table-head-custom table-vertical-center"
          id="kt_advance_table_widget_1"
        >
          <thead>
            <tr class="text-left">
              <th class="pr-0" style="width: 50px">#</th>
              <th class="pr-0" style="width: 50px">Πελατης</th>
              <th style="min-width: 200px"></th>
              <th style="min-width: 150px">Διευθυνση</th>
              <th style="min-width: 150px">Ημερομηνια</th>
              <th style="min-width: 150px">Τηλεφωνο</th>
              <th style="min-width: 150px">Email</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in list">
              <tr v-bind:key="i">
                <td class="pr-0">
                </td>
                <td class="pr-0">
                  <div class="symbol symbol-50 symbol-light mt-1">
                    <span class="symbol-label">
                      
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ item.text1 }}</a
                  >
                  <span
                    class="text-muted font-weight-bold text-muted d-block"
                    >{{ item.text2 }}</span
                  >
                </td>
                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >{{ item.text3 }}</span
                  >
                  <span class="text-muted font-weight-bold">{{
                    item.text4
                  }}</span>
                </td>
                <td>
                </td>
                <td class="pr-0 text-right">
                </td>
                <td class="pr-0 text-right">
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
export default {
  name: "PurchasedCases",
  data() {
    return {
      list: [
        {
          text0: "media/svg/avatars/001-boy.svg",
          text1: "Brad Simmons",
          text2: "HTML, JS, ReactJS",
          text3: "Intertico",
          text4: "Web, UI/UX Design",
          text5: "65%",
          text6: "danger"
        },
        {
          text0: "media/svg/avatars/018-girl-9.svg",
          text1: "Jessie Clarcson",
          text2: "C#, ASP.NET, MS SQL",
          text3: "Agoda",
          text4: "Houses & Hotels",
          text5: "83%",
          text6: "success"
        },
        {
          text0: "media/svg/avatars/047-girl-25.svg",
          text1: "Lebron Wayde",
          text2: "PHP, Laravel, VueJS",
          text3: "RoadGee",
          text4: "Transportation",
          text5: "47%",
          text6: "primary"
        },
        {
          text0: "media/svg/avatars/014-girl-7.svg",
          text1: "Natali Trump",
          text2: "Python, PostgreSQL, ReactJS",
          text3: "The Hill",
          text4: "Insurance",
          text5: "71%",
          text6: "danger"
        }
      ],
      checked: false
    };
  },
};
</script>
